
import { Vue, Component } from "vue-property-decorator";
import { apiMailConfig, apiMailConfigEdit } from "@/api/setting";

export interface MailConfigParams {
    smtp_auth_password: string,
    smtp_auth_username: string,
    smtp_port: number,
    smtp_server: string
}

@Component
export default class MailConfig extends Vue {
    form: MailConfigParams = {
        smtp_auth_password: "",
        smtp_auth_username: "",
        smtp_port: 465,
        smtp_server: ""
    }

    rules: object = {
        smtp_server: [
            {
                pattern:
                    /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/,
                message: "请输入合法的服务器地址",
                required: true,
                trigger: "blur",
            },
        ],
        smtp_port: [
            {
                type: "number",
                min: 1,
                max: 65535,
                message: "请输入合法的端口",
                required: true,
                trigger: "blur",
            }
        ],
        smtp_auth_username: [
            {
                min: 3,
                max: 30,
                message: "请输入合法的用户名",
                required: true,
                trigger: "blur",
            }
        ],
        smtp_auth_password: [
            {
                min: 3,
                max: 30,
                message: "请输入合法的密码",
                required: true,
                trigger: "blur",
            }
        ]
    }

    created() {
        this.initFormData();
    }

    async onSubmitFrom(formName: string): Promise<void> {
        const refs = this.$refs[formName] as HTMLFormElement;
        refs.validate(async (valid: boolean): Promise<void> => {
            if (!valid) return;
            const loading = this.$loading({ text: "保存中" });

            await apiMailConfigEdit({
                ...this.form,
            }).finally(() => {
                loading.close();
            });
        });
    }

    async initFormData(): Promise<void> {
        const res = await apiMailConfig<MailConfigParams>();
        this.form = {
            ...res
        };
    }
}
